import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const targetDate = "2024-02-12T00:00:00.000Z";
  const [timeLeft, setTimeLeft] = useState(new Date(targetDate).getTime() - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = new Date(targetDate).getTime() - now;
      setTimeLeft(distance);
      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft(0);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return (
    <div className="App" style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
      <header style={{ backgroundColor: 'black', padding: '20px', textAlign: 'center' }}>
        <h1 style={{ color: 'white', fontSize: '32px', margin: '0' }}>LUI404</h1>
      </header>
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black' }}>
        <div className="countdown" style={{ color: 'white', fontSize: '120px', textAlign: 'center' }}>
          {days} : {hours} : {minutes} : {seconds}
        </div>
      </div>
    </div>
  );
}

export default App;
